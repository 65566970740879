<template>
  <div>
     <b-modal @hidden="resetModal" ref="modal-merchant" size="xl" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Create a Merchant Account
           </h5>
        </template>
        <div class="row align-items-top">
              <div class="mb-3 col-12 col-lg-6">
                 <label>Login Username</label>
                 <input
                    v-model.trim="formData.username"
                    @input="formData.username = $event.target.value.toLowerCase()"
                    type="text"
                    class="form-control"
                    placeholder="Username"
                    name="username"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.username.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formData.username.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.username.required"
                       >Username is required.</span
                       >
                    <span v-else-if="!$v.formData.username.validUsername"
                       >Username must contain only lowercase alphabets and numbers.</span
                       >
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Merchant Name</label>
                 <input
                    v-model.trim="formData.name"
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    name="name"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.name.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formData.name.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.name.required"
                       >Name is required.</span
                       >
                 </div>
              </div>
              
              <div class="mb-3 col-12 col-lg-6">
                 <label>Password</label>
                 <div class="position-relative">
                    <input
                       v-model="formData.password"
                       :type="passwordFieldType"
                       name="password"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.formData.password.$error,
                       }"
                       placeholder="Password"
                       />
                    <div
                       v-if="modalSubmit && $v.formData.password.$error"
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.formData.password.required"
                          >Password is required.</span
                          >
                       <span v-if="!$v.formData.password.minLength"
                          >Password must be at least 8 characters.</span
                          >
                       <span v-else-if="!$v.formData.password.alpha">
                       Password must be at least 8 characters and contain at least one number, as well as both lowercase and uppercase letters, along with special characters (e.g., HengHeng357#@, Admin12#@, etc.).</span
                          >
                    </div>
                    <div
                       id="eye"
                       class="user-select-none"
                       @click="switchVisibility()"
                       >
                       <i
                          :class="classicon"
                          id="eyetype"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Confirm Password</label>
                 <div class="position-relative">
                    <input
                       v-model="formData.confirmPassword"
                       :type="passwordFieldType2"
                       name="confirmPassword"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.formData.confirmPassword.$error,
                       }"
                       placeholder="Confirm Password"
                       />
                    <div
                       v-if="
                       modalSubmit && $v.formData.confirmPassword.$error
                       "
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.formData.confirmPassword.required"
                          >Confirm password is required.</span
                          >
                       <span
                          v-else-if="
                          !$v.formData.confirmPassword.sameAsPassword
                          "
                          >Confirm password does not match the new
                       password.</span
                          >
                    </div>
                    <div
                       id="eye2"
                       class="user-select-none"
                       @click="switchVisibility2()"
                       >
                       <i
                          :class="classicon2"
                          id="eyetype2"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Website</label>
                 <input
                    v-model.trim="formData.websiteUrl"
                    type="text"
                    class="form-control"
                    placeholder="Website"
                    name="WebsiteUrl"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.websiteUrl.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formData.websiteUrl.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.websiteUrl.required"
                       >Website url is required.</span
                       >
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Remarks</label>
                 <input
                    v-model.trim="formData.remarks"
                    type="text"
                    class="form-control"
                    placeholder="Remarks"
                    name="Remarks"/>
              </div>

              <div class="mb-3 col-12 col-lg-6">
                 <label>Payin Callback Url (Reponse Url)</label>
                 <input
                    type="text"
                    v-model.trim="formData.callBackUrl"
                    class="form-control"
                    placeholder="Payin Callback Url (Reponse Url)"
                    name="callBackUrl"
                    />
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Whitelist IP Addresses <small class="fw-normal font-size-11 text-muted">Separated by commas (,)</small></label>
                 <input
                    v-model.trim="formData.whitelistIP"
                    type="text"
                    class="form-control"
                    placeholder="Enter multiple IP addresses separated by commas (,)"
                    name="whitelistIP"
                    />
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Category</label>

                 <multiselect v-model="formData.category" :options="pgCategory" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Category" label="" track-by="" class="text-capitalize" :preselect-first="true" :disabled="loading" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.category.$error
                  }">
                 <template slot="option" slot-scope="props"><span class="option__title text-capitalize cursor-pointer">{{ props.option }}</span></template>
                 <template slot="tag" slot-scope="{ option, remove }">
                  <span class="custom__tag"><span class="badge font-size-14 p-1 px-2 me-1 mb-0 bg-soft-warning text-orange text-capitalize cursor-pointer">{{ option }} <span class="custom__remove" @click="remove(option)"><i class="uil uil-minus-circle"></i></span></span>
                 </span>
                  </template>
                 <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                 <div
                    v-if="modalSubmit && $v.formData.category.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.category.required"
                       >Category is required.</span
                       >
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Time Zone</label>
                 <multiselect v-model="formData.timeZone" :options="timeZone" :multiple="false" placeholder="Select Time Zone" label="name" track-by="name"  :preselect-first="true" :disabled="loading" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.timeZone.$error
                  }">
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.name }} (UTC{{ props.option.value }})
                  </template>
                  <template slot="option" slot-scope="props">
                    {{ props.option.name  }} (UTC{{ props.option.value }})
                  </template>
                 <span slot="noResult">Oops! No time zone found.</span>
                </multiselect>
                 <div
                    v-if="modalSubmit && $v.formData.timeZone.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.timeZone.required"
                       >Time zone is required.</span
                       >
                 </div>
              </div>
              <p class="text-muted font-size-12"><i class="uil uil-exclamation-triangle font-size-15 text-warning me-2"></i> Password is Case Sensitive. </p>
              <hr>
              <div class="mb-3">
                <label>Reseller</label>
              
                <multiselect v-model="selectedReseller" :options="reseller" label="account_name" :class="{'mb-2':selectedReseller.account_db_code!=='all' && selectedReseller.account_db_code!=='-1'}" placeholder="Select Reseller" :close-on-select="true" :allow-empty="false" deselect-label="" :show-on-select="true" :disabled="loading" @input="getData()">
                      <template slot="singleLabel" slot-scope="props">
                        <span v-if="props.option.account_db_code!=='-1'">
                          <span class="option__title">
                            <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                              <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'">Z</span>
                              <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                            </span>

                            {{ props.option.account_name }} 
                          </span>
                        </span>
                        <span v-else>
                            {{ props.option.account_name }}
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <span v-if="props.option.account_db_code!=='-1'">
                          <span class="option__title">
                            <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                              <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'">Z</span>
                              <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                            </span>
                            
                            {{ props.option.account_name }} 
                          </span>
                        </span>
                        <span v-else>
                          {{ props.option.account_name }} 
                        </span>
                      </template>
                      <span slot="noResult">Oops! No reseller found. </span>
                    </multiselect>
              </div>
              <div class="mb-3">
                <label>Currency Enabled</label>
                <div v-if="formData.contract_selected.length">
                  <span class="badge bg-soft-success font-size-14 py-1 px-2 me-1 mb-2" v-for="(value, index) in formData.contract_selected" :key="index">
                    <span v-if="value.status==1">{{value.name}}</span>
                  </span>
                   <div v-if="!formData.contract_selected.some(e => e.status==1)"> -</div>
                </div>
                <div v-else> No Contract Available</div>
              </div>
              <div class="table-responsive text-nowrap font-size-14">
                <table class="table table-bordered align-middle placeholder-glow" v-if="loading">
                  <thead class="custom_top_data_bar text-uppercase">
                    <tr>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span class="placeholder col-12 col-lg-8"></span></td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                    </tr>
                    <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                    <tr>
                      <td>
                      </td>
                      <td class="align-top text-wrap">
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td class="align-top">
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td class="align-top">
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td class="align-top">
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                    </tr>
                    <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                    </tr>
                    <tr>
                      <td><span class="placeholder col-12 col-lg-8"></span></td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <b-dropdown id="dropdown-grouped" class="mb-3 me-2" variant="info" ref="dropdown" v-if="formData.contract_selected.length && !loading">
                  <template #button-content>
                    <i class="uil uil-edit-alt"></i> Quick Update
                  </template>
                  <b-dropdown-header>
                    Both the total rate and extra charges will increase <br> based on the input values below.
                  </b-dropdown-header>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-1" header="">
                    <b-dropdown-form class="p-3 pt-2">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <div class="d-flex align-items-center mb-3">
                            <div class="w-100 me-1">Set All Inputs</div>
                              <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.all_value" placeholder="All Inputs" maxlength="6" @input="inputNumberOnlyV2($event, 'quickUpdate','all_value'), updateAllInput()" >  
                          </div>
                          <b-dropdown-divider></b-dropdown-divider>
                        </div>
                        <div class="col-12">
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Payin</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.payin_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','payin_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.payin_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','payin_extra_charge')" >
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Payout</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.payout_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','payout_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.payout_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','payout_extra_charge')" >
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Settlement</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.settlement_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','settlement_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.settlement_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','settlement_extra_charge')" >
                          
                          </div>
                          <div class="d-flex align-items-center">
                            <div class="w-100 me-1">E-Wallet</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.ewallet_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','ewallet_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.ewallet_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','ewallet_extra_charge')" >
                          </div>
                        </div>
                      </div>
                      </b-dropdown-form> 
                  </b-dropdown-group>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-form class="p-3 py-1">
                   <div class="d-flex gap-2">
                   <button type="button" class="btn btn-secondary w-100" @click="resetRateAndExtraCharge('formData')">Reset All</button>
                   <button type="button" class="btn btn-info  w-100" @click="quickUpdateRateAndExtraCharge('formData')">Update All</button>
                  </div>
                  </b-dropdown-form>
                </b-dropdown>
                <b-dropdown id="dropdown-grouped" class="mb-3" variant="info" ref="dropdown3" v-if="formData.contract_selected.length && !loading">
                  <template #button-content>
                    <i class="uil uil-usd-circle"></i> Quick Enable Currency
                  </template>
                  <b-dropdown-header>
                  Quick Enable Currency  
                  </b-dropdown-header>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-1" header="">
                    <b-dropdown-form class="p-3 py-1">
                      <div class="row">
                        <div class="mb-1" v-for="(value, index) in $v.formData.contract_selected.$each.$iter" :key="index">
                        <div class="d-flex align-items-center text-lg-end mb-0">
                            <h4 class="font-size-14 me-2">{{ value.currency.$model || '-' }}</h4>
                            <!-- <span class="text-danger">upline_disable: [{{ uplineCost[index].upline_disable }}]</span> -->
                            <div class="form-check form-switch form-check-custom ms-auto font-size-14 text-nowrap mb-1" >
                              <input class="form-check-input" type="checkbox" :id="index" true-value="1" false-value="0" disabled="true" v-if="uplineCost[index].upline_disable">
                              <input class="form-check-input" type="checkbox" :id="index" v-model="formData.contract_selected[index].status" true-value="1" false-value="0" v-else>
                              <label class="form-check-label fw-normal font-size-14" :for="index"> {{ formData.contract_selected[index].status == 1 ? '' : '' }} </label>
                            </div>
                        </div>
                        <b-dropdown-divider></b-dropdown-divider>
                      </div>
                      </div>
                      </b-dropdown-form> 
                  </b-dropdown-group>
                </b-dropdown>
              </div>
              <div v-if="!loading">
                <div class="mb-3" v-for="(value, index) in $v.formData.contract_selected.$each.$iter" :key="index">
                  <div class="d-flex text-lg-end mb-1">
                      <h4 class="font-size-14">Currency Rate and Extra Charges ({{ value.currency.$model || '-' }})</h4>
                      <div class="form-check form-switch form-check-custom ms-auto font-size-14 text-nowrap" >
                      <input class="form-check-input" type="checkbox" :id="index" true-value="1" false-value="0" disabled="true" v-if="uplineCost[index].upline_disable">
                      <input class="form-check-input" type="checkbox" :id="index" v-model="formData.contract_selected[index].status" true-value="1" false-value="0" v-else>
                      <label class="form-check-label fw-normal font-size-14" :for="index"> {{ formData.contract_selected[index].status == 1 ? 'Enabled' : 'Disabled' }} Contract</label>
                    </div>
                  </div>
                  <div class="table-responsive text-nowrap font-size-14 mb-3">
                    <table class="table table-bordered align-middle custom-table mb-0">
                      <thead class="custom_top_data_bar text-uppercase font-size-12">
                        <tr>
                          <th>Current Rate <br>and Extra Charge</th>
                          <th>Payin Rate <br>and Extra Charges</th>
                          <th>Payout Rate <br>and Extra Charges</th>
                          <th>Settlement Rate<br>and Extra Charges</th>
                          <th>E-Wallet Rate <br> and Extra Charges</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Company/Upline</td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{parseFloat(uplineCost[index].payin_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].payin_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{parseFloat(uplineCost[index].payout_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].payout_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{parseFloat(uplineCost[index].settlement_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].settlement_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{parseFloat(uplineCost[index].ewallet_rate).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{parseFloat(uplineCost[index].ewallet_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Reseller Earning Rate and Extra Charges ({{ value.currency.$model || '-' }})</td></tr>                      
                        <tr>
                          <td>Merchant 
                            <input type="text" class="form-control" v-model="formData.contract_selected[index].currency" hidden>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payin_rate.$error}" 
                                  v-model="value.payin_rate.$model" placeholder="Rate" maxlength="6" @input="inputNumberOnlyV4($event, 'formData','payin_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.payin_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payin_rate.required"
                                    >The payin rate is required.</span
                                    >
                                  <span v-else-if="!value.payin_rate.between"
                                    >The value should be between {{parseFloat(formData.contract_selected[index].min_payin_rate).toFixed(2)}} and 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payin_extra_charge.$error}" 
                                  v-model="value.payin_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV4($event, 'formData','payin_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.payin_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payin_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.payin_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payout_rate.$error}" 
                                  v-model="value.payout_rate.$model" placeholder="Rate" maxlength="6" @input="inputNumberOnlyV4($event, 'formData','payout_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.payout_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payout_rate.required"
                                    >The payout rate is required.</span
                                    >
                                  <span v-else-if="!value.payout_rate.between"
                                    >The value should be between {{parseFloat(formData.contract_selected[index].min_payout_rate).toFixed(2)}} and 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payout_extra_charge.$error}" 
                                  v-model="value.payout_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV4($event, 'formData','payout_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.payout_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payout_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.payout_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.settlement_rate.$error}" 
                                  v-model="value.settlement_rate.$model" placeholder="Rate" maxlength="6" @input="inputNumberOnlyV4($event, 'formData','settlement_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.settlement_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.settlement_rate.required"
                                    >The settlement rate is required.</span
                                    >
                                  <span v-else-if="!value.settlement_rate.between"
                                    >The value should be between {{parseFloat(formData.contract_selected[index].min_settlement_rate).toFixed(2)}} and 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.settlement_extra_charge.$error}" 
                                  v-model="value.settlement_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV4($event, 'formData','settlement_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.settlement_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.settlement_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.settlement_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.ewallet_rate.$error}" 
                                  v-model="value.ewallet_rate.$model" placeholder="Rate" maxlength="6" @input="inputNumberOnlyV4($event, 'formData','ewallet_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.ewallet_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.ewallet_rate.required"
                                    >The e-wallet rate is required.</span
                                    >
                                  <span v-else-if="!value.ewallet_rate.between"
                                    >The value should be between {{parseFloat(formData.contract_selected[index].min_ewallet_rate).toFixed(2)}} and 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.ewallet_extra_charge.$error}" 
                                  v-model="value.ewallet_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV4($event, 'formData','ewallet_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.ewallet_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.ewallet_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.ewallet_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Merchant Rate and Extra Charges  ({{ value.currency.$model || '-' }})</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].payin_rate) + parseFloat(value.payin_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].payin_extra_charge) + parseFloat(value.payin_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].payout_rate) + parseFloat(value.payout_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].payout_extra_charge) + parseFloat(value.payout_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].settlement_rate) + parseFloat(value.settlement_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].settlement_extra_charge) + parseFloat(value.settlement_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].ewallet_rate) + parseFloat(value.ewallet_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].ewallet_extra_charge) + parseFloat(value.ewallet_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                 <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-merchant'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="addMerchantRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Creating...</span>
                 <span v-else> Create </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-merchant-edit" size="xl" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Edit Merchant Account
           </h5>
        </template>
        <div class="row align-items-top">
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Merchant Username</label>
                 <div><span class="badge bg-soft-info font-size-14">{{ updateData.username || '-' }}</span></div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                <div class="row">
                  <div class="col-4">
                    <div class="align-items-center">
                      <label class="mb-1 me-2">Account Status</label>
                      <div>
                        <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                          <div v-if="uplineInfo.length"> 
                          <input class="form-check-input" type="checkbox" role="switch" :disabled="loading || (uplineInfo[uplineInfo.length - 1].status_flag==0)" v-if="uplineInfo[uplineInfo.length - 1].status_flag==0">
                          <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.status" true-value="1" false-value="0" :disabled="loading" v-else>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="align-items-center">
                      <label class="mb-1 me-2">API Connect</label>
                      <div>
                        <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                          <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.api_enabled" true-value="1" false-value="0" :disabled="loading">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="align-items-center">
                      <label class="mb-1 me-2">Login Block
                        <span v-if="formEditData.login_block==1" class="ms-1 badge bg-soft-danger rounded-pill font-size-12">Blocked</span>
                      </label>
                      <div>
                        <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                          <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.login_block" true-value="1" false-value="0" :disabled="loading">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Merchant Code</label>
                 <div><span class="badge bg-soft-info font-size-14">{{ formEditData.merchant_code || '-'}}</span></div>
              </div>
              <div class="mb-4 col-12 col-lg-6">
                 <label class="mb-1">Merchant Key</label>
                 <div><span class="badge bg-soft-info font-size-14">{{ formEditData.merchant_key || '-'}}</span></div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Merchant Name</label>
                 <input
                    v-model="formEditData.name"
                    type="text"
                    class="form-control"
                    placeholder="Merchant Name"
                    name="name"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.name.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formEditData.name.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.name.required"
                       >Name is required.</span
                       >
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Website</label>
                 <input
                    v-model="formEditData.websiteUrl"
                    type="text"
                    class="form-control"
                    placeholder="Website"
                    name="WebsiteUrl"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.websiteUrl.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formEditData.websiteUrl.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.websiteUrl.required"
                       >Website url is required.</span
                       >
                    <span v-else-if="!$v.formEditData.websiteUrl.url"
                       >Please enter a valid website url.</span
                       >
                 </div>
              </div>
           

              <div class="mb-3 col-12 col-lg-6">
                 <label>Payin Callback Url (Reponse Url)</label>
                 <input
                    type="text"
                    v-model.trim="formEditData.callBackUrl"
                    class="form-control"
                    placeholder="Payin Callback Url (Reponse Url)"
                    name="callBackUrl"
                    />
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Whitelist IP Addresses <small class="fw-normal font-size-11 text-muted">Separated by commas (,)</small></label>
                 <input
                    v-model.trim="formEditData.whitelistIP"
                    type="text"
                    class="form-control"
                    placeholder="Enter multiple IP addresses separated by commas (,)"
                    name="whitelistIP"
                    />
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Category</label>
                 <multiselect v-model="formEditData.category" :options="pgCategory" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Category" label="" track-by="" class="text-capitalize" :preselect-first="true" :disabled="loading" :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.category.$error,
                  }">
                 <template slot="option" slot-scope="props"><span class="option__title text-capitalize cursor-pointer">{{ props.option }}</span></template>
                 <template slot="tag" slot-scope="{ option, remove }">
                  <span class="custom__tag"><span class="badge font-size-14 p-1 px-2 me-1 mb-0 bg-soft-warning text-orange text-capitalize cursor-pointer">{{ option }} <span class="custom__remove" @click="remove(option)"><i class="uil uil-minus-circle"></i></span></span>
                 </span>
                  </template>
                 <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                 <div
                    v-if="modalSubmit && $v.formEditData.category.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.category.required"
                       >Category is required.</span
                       >
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Remarks</label>
                 <input
                    v-model="formEditData.remarks"
                    type="text"
                    class="form-control"
                    placeholder="Remarks"
                    name="Remarks"
                    />
              </div>
              
              <div class="mb-3 col-12 col-lg-6">
                 <label>Time Zone</label>
                 <multiselect v-model="formEditData.timeZone" :options="timeZone" :multiple="false" placeholder="Select Time Zone" label="name" track-by="name"  :preselect-first="true" :disabled="loading" :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.timeZone.$error
                  }">
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.name }} (UTC{{ props.option.value }})
                  </template>
                  <template slot="option" slot-scope="props">
                    {{ props.option.name  }} (UTC{{ props.option.value }})
                  </template>
                 <span slot="noResult">Oops! No time zone found. </span>
                </multiselect>
                 <div
                    v-if="modalSubmit && $v.formEditData.timeZone.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.timeZone.required"
                       >Time zone is required.</span
                       >
                 </div>
              </div>
              <div class="mb-4 col-12 col-lg-6">
              <label>Password</label>
                  <div> <button class="btn btn-info py-2 font-size-15 rounded" @click="showResetPasswordModal()">
                      <i class="uil uil-lock-alt"></i> Reset Password</button></div>
              </div>
              <hr>
              <div v-if="updateData.username!==defaultName" class="mb-3">
                <label class="mb-1 text-capitalize">Merchant under below reseller</label>
                <div class="form-control bg-light border-0" v-if="uplineInfo.length"> {{ uplineInfo[uplineInfo.length - 1].name }}</div>
              </div>
              <div class="mb-3">
                <label>Currency Enabled</label>
                <div v-if="formEditData.contract_selected.length">
                  <span class="badge bg-soft-success font-size-14 py-1 px-2 me-1 mb-2" v-for="(value, index) in formEditData.contract_selected" :key="index">
                    <span v-if="value.status==1">{{value.name}}</span>
                  </span>
                   <div v-if="!formEditData.contract_selected.some(e => e.status==1)"> -</div>
                </div>
                <div v-else> -</div>
              </div>
              
              <table class="table table-bordered align-middle placeholder-glow" v-if="loading">
                <thead class="custom_top_data_bar text-uppercase">
                  <tr>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                  <tr>
                    <td>
                    </td>
                    <td class="align-top text-wrap">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                  </tr>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
              
              <div>
                <b-dropdown id="dropdown-grouped" class="mb-3 me-2" variant="info" ref="dropdown2" v-if="formEditData.contract_selected.length && !loading">
                  <template #button-content>
                    <i class="uil uil-edit-alt"></i> Quick Update
                  </template>
                  <b-dropdown-header>
                    Both the total rate and extra charges will increase <br> based on the input values below.
                  </b-dropdown-header>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-1" header="">
                    <b-dropdown-form class="p-3 pt-2">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <div class="d-flex align-items-center mb-3">
                            <div class="w-100 me-1">Set All Inputs</div>
                              <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.all_value" placeholder="All Inputs" maxlength="6" @input="inputNumberOnlyV2($event, 'quickUpdate','all_value'), updateAllInput()" >  
                          </div>
                          <b-dropdown-divider></b-dropdown-divider>
                        </div>
                        <div class="col-12">
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Payin</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.payin_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','payin_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.payin_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','payin_extra_charge')" >
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Payout</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.payout_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','payout_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.payout_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','payout_extra_charge')" >
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Settlement</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.settlement_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','settlement_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.settlement_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','settlement_extra_charge')" >
                          
                          </div>
                          <div class="d-flex align-items-center">
                            <div class="w-100 me-1">E-Wallet</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.ewallet_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','ewallet_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.ewallet_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','ewallet_extra_charge')" >
                          </div>
                        </div>
                      </div>
                      </b-dropdown-form> 
                  </b-dropdown-group>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-form class="p-3 py-1">
                   <div class="d-flex gap-2">
                   <button type="button" class="btn btn-secondary w-100" @click="resetRateAndExtraCharge('formEditData')">Reset All</button>
                   <button type="button" class="btn btn-info  w-100" @click="quickUpdateRateAndExtraCharge('formEditData')">Update All</button>
                  </div>
                  </b-dropdown-form>
                </b-dropdown>

                <b-dropdown id="dropdown-grouped" class="mb-3" variant="info" ref="dropdown3" v-if="formEditData.contract_selected.length && !loading">
                  <template #button-content>
                    <i class="uil uil-usd-circle"></i> Quick Enable Currency
                  </template>
                  <b-dropdown-header>
                  Quick Enable Currency  
                  </b-dropdown-header>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-1" header="">
                    <b-dropdown-form class="p-3 py-1">
                      <div class="row">
                        <div class="mb-1" v-for="(value, index) in $v.formEditData.contract_selected.$each.$iter" :key="index">
                        <div class="d-flex align-items-center text-lg-end mb-0">
                            <h4 class="font-size-14 me-2">{{ value.currency.$model || '-' }}</h4>
                            <!-- <span class="text-danger">upline_disable: [{{ uplineCost[index].upline_disable }}]</span> -->
                            <div class="form-check form-switch form-check-custom ms-auto font-size-14 text-nowrap mb-1" >
                              <input class="form-check-input" type="checkbox" :id="index" true-value="1" false-value="0" disabled="true" v-if="uplineCost[index].upline_disable">
                              <input class="form-check-input" type="checkbox" :id="index" v-model="formEditData.contract_selected[index].status" true-value="1" false-value="0" v-else>
                              <label class="form-check-label fw-normal font-size-14" :for="index"> {{ formEditData.contract_selected[index].status == 1 ? '' : '' }} </label>
                            </div>
                        </div>
                        <b-dropdown-divider></b-dropdown-divider>
                      </div>
                      </div>
                      </b-dropdown-form> 
                  </b-dropdown-group>
                </b-dropdown>
               
              </div>
              <div v-if="!loading">
                <div class="mb-3" v-for="(value, index) in $v.formEditData.contract_selected.$each.$iter" :key="index">
                  <div class="d-flex text-lg-end mb-1">
                      <h4 class="font-size-14">Currency Rate and Extra Charges ({{ value.currency.$model || '-' }})</h4>
                      <!-- <span class="text-danger">upline_disable: [{{ uplineCost[index].upline_disable }}]</span> -->
                      <div class="form-check form-switch form-check-custom ms-auto font-size-14 text-nowrap" >
                        <input class="form-check-input" type="checkbox" :id="index" true-value="1" false-value="0" disabled="true" v-if="uplineCost[index].upline_disable">
                        <input class="form-check-input" type="checkbox" :id="index" v-model="formEditData.contract_selected[index].status" true-value="1" false-value="0" v-else>
                        <label class="form-check-label fw-normal font-size-14" :for="index"> {{ formEditData.contract_selected[index].status == 1 ? 'Enabled' : 'Disabled' }} Contract</label>
                      </div>
                  </div>

                  <div class="table-responsive text-nowrap font-size-14 mb-4">
                    <table class="table table-bordered align-middle custom-table mb-1">
                      <thead class="custom_top_data_bar text-uppercase font-size-12">
                        <tr>
                          <th>Current Rate <br>and Extra Charge</th>
                          <th>Payin Rate <br>and Extra Charges</th>
                          <th>Payout Rate <br>and Extra Charges</th>
                          <th>Settlement Rate<br>and Extra Charges</th>
                          <th>E-Wallet Rate <br> and Extra Charges</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Company/Upline</td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{uplineCost[index].payin_rate.toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{uplineCost[index].payin_extra_charge.toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{uplineCost[index].payout_rate.toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{uplineCost[index].payout_extra_charge.toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{uplineCost[index].settlement_rate.toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{uplineCost[index].settlement_extra_charge.toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                              {{uplineCost[index].ewallet_rate.toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{uplineCost[index].ewallet_extra_charge.toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Reseller Earning Rate and Extra Charges  ({{ value.currency.$model || '-' }})</td></tr>                      
                        <tr>
                          <td>Merchant 
                            <input type="text" class="form-control" v-model="formEditData.contract_selected[index].currency" hidden>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payin_rate.$error}" 
                                  v-model="value.payin_rate.$model" placeholder="Rate" maxlength="6" @input="inputNumberOnlyV4($event, 'formEditData','payin_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.payin_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payin_rate.required"
                                    >The payin rate is required.</span
                                    >
                                  <span v-else-if="!value.payin_rate.between"
                                    >The value should be between {{parseFloat(formEditData.contract_selected[index].min_payin_rate).toFixed(2)}} and 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payin_extra_charge.$error}" 
                                  v-model="value.payin_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV4($event, 'formEditData','payin_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.payin_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payin_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.payin_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payout_rate.$error}" 
                                  v-model="value.payout_rate.$model" placeholder="Rate" maxlength="6" @input="inputNumberOnlyV4($event, 'formEditData','payout_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.payout_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payout_rate.required"
                                    >The payout rate is required.</span
                                    >
                                  <span v-else-if="!value.payout_rate.between"
                                    >The value should be between {{parseFloat(formEditData.contract_selected[index].min_payout_rate).toFixed(2)}} and 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payout_extra_charge.$error}" 
                                  v-model="value.payout_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV4($event, 'formEditData','payout_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.payout_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payout_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.payout_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.settlement_rate.$error}" 
                                  v-model="value.settlement_rate.$model" placeholder="Rate" maxlength="6" @input="inputNumberOnlyV4($event, 'formEditData','settlement_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.settlement_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.settlement_rate.required"
                                    >The settlement rate is required.</span
                                    >
                                  <span v-else-if="!value.settlement_rate.between"
                                    >The value should be between {{parseFloat(formEditData.contract_selected[index].min_settlement_rate).toFixed(2)}} and 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.settlement_extra_charge.$error}" 
                                  v-model="value.settlement_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV4($event, 'formEditData','settlement_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.settlement_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.settlement_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.settlement_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="row">
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.ewallet_rate.$error}" 
                                  v-model="value.ewallet_rate.$model" placeholder="Rate" maxlength="6" @input="inputNumberOnlyV4($event, 'formEditData','ewallet_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.ewallet_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.ewallet_rate.required"
                                    >The e-wallet rate is required.</span
                                    >
                                  <span v-else-if="!value.ewallet_rate.between"
                                    >The value should be between {{parseFloat(formEditData.contract_selected[index].min_ewallet_rate).toFixed(2)}} and 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="col-6">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.ewallet_extra_charge.$error}" 
                                  v-model="value.ewallet_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV4($event, 'formEditData','ewallet_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.ewallet_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.ewallet_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.ewallet_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Merchant Rate and Extra Charges  ({{ value.currency.$model || '-' }})</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].payin_rate) + parseFloat(value.payin_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].payin_extra_charge) + parseFloat(value.payin_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].payout_rate) + parseFloat(value.payout_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].payout_extra_charge) + parseFloat(value.payout_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].settlement_rate) + parseFloat(value.settlement_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].settlement_extra_charge) + parseFloat(value.settlement_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="row text-center">
                              <div class="col">
                                {{ parseFloat(parseFloat(uplineCost[index].ewallet_rate) + parseFloat(value.ewallet_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="col">
                              + {{ parseFloat(parseFloat(uplineCost[index].ewallet_extra_charge) + parseFloat(value.ewallet_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-merchant-edit'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="updateMerchantRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Updating...</span>
                 <span v-else> Update </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-merchant-edit-pg" size="xl" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Edit Merchant Account - PG Settings
           </h5>
        </template>
        <div class="row align-items-top">
           <div class="mb-3 col-12 col-lg-6">
                <label class="mb-1">Merchant Username</label>
                <div><span class="badge bg-soft-info font-size-14">{{ updateData.username || '-' }}</span></div>
           </div>
           <div class="mb-3 col-12 col-lg-6">
                <label class="mb-1">Merchant Name</label>
                <div><span class="badge bg-soft-info font-size-14">{{ updateData.account_name || '-' }}</span></div>
           </div>
           <div class="mb-3 col-12 col-lg-6">
                <label class="mb-1">Merchant Code</label>
                <div><span class="badge bg-soft-info font-size-14">{{ formEditPGData.merchant_code || '-'}}</span></div>
           </div>
           <div class="mb-4 col-12 col-lg-6">
                <label class="mb-1">Merchant Key</label>
                <div><span class="badge bg-soft-info font-size-14">{{ formEditPGData.merchant_key || '-'}}</span></div>
           </div>
           <hr>
           <div class="mb-2">
              <label>Current Contract Enabled</label>
              <div v-if="ownCost.length">
                <span class="badge bg-soft-success font-size-14 py-1 px-2 me-1 mb-2" v-for="(value, index) in ownCost" :key="index">
                  <span v-if="value.status==1">{{value.contract_name}}</span>
                </span>
                  <div v-if="!ownCost.some(e => e.status==1)"> -</div>
              </div>
              <div v-else> -</div>
            </div>
            
           <table class="table table-bordered align-middle placeholder-glow" v-if="loading">
              <thead class="custom_top_data_bar text-uppercase">
                <tr>
                  <th><span class="placeholder col-12 col-lg-8"></span></th>
                  <th><span class="placeholder col-12 col-lg-8"></span></th>
                  <th><span class="placeholder col-12 col-lg-8"></span></th>
                  <th><span class="placeholder col-12 col-lg-8"></span></th>
                  <th><span class="placeholder col-12 col-lg-8"></span></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><span class="placeholder col-12 col-lg-8"></span></td>
                  <td>
                    <span class="placeholder col-12 col-lg-8"></span>
                  </td>
                  <td>
                    <span class="placeholder col-12 col-lg-8"></span>
                  </td>
                  <td>
                    <span class="placeholder col-12 col-lg-8"></span>
                  </td>
                  <td>
                    <span class="placeholder col-12 col-lg-8"></span>
                  </td>
                </tr>
                <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                <tr>
                  <td>
                  </td>
                  <td class="align-top text-wrap">
                    <span class="placeholder col-12 col-lg-8"></span>
                  </td>
                  <td class="align-top">
                    <span class="placeholder col-12 col-lg-8"></span>
                  </td>
                  <td class="align-top">
                    <span class="placeholder col-12 col-lg-8"></span>
                  </td>
                  <td class="align-top">
                    <span class="placeholder col-12 col-lg-8"></span>
                  </td>
                </tr>
                <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                </tr>
                <tr>
                  <td><span class="placeholder col-12 col-lg-8"></span></td>
                  <td>
                    <span class="placeholder col-12 col-lg-8"></span>
                  </td>
                  <td>
                    <span class="placeholder col-12 col-lg-8"></span>
                  </td>
                  <td>
                    <span class="placeholder col-12 col-lg-8"></span>
                  </td>
                  <td>
                    <span class="placeholder col-12 col-lg-8"></span>
                  </td>
                </tr>
              </tbody>
           </table>
            
           <div v-if="!loading">
              <p class="custom_top_data_bar p-2 font-size-14 rounded"><i class="uil uil-exclamation-circle text-primary me-1 font-size-16"></i> By Default Setting is Auto Select PG</p>
              <div class="mb-3" v-for="(value, index) in ownCost" :key="index">
                <div class="d-flex text-lg-end mb-1">
                    <h4 class="font-size-14">Payment Gateway ({{ value.contract_currency || '-' }})</h4>
                    <div class="form-check form-switch form-check-custom ms-auto font-size-14 text-nowrap" >
                      <input class="form-check-input" type="checkbox" :id="index" v-model="value.manual_pg" true-value="1" false-value="0" @change="value.selected_pg=[]">
                      <label class="form-check-label fw-normal font-size-14" :for="index"> {{ ownCost[index].manual_pg == 1 ? 'Manual' : 'Manual' }} Select</label>
                    </div>
                </div>
                <div>
                  <multiselect v-model="value.selected_pg" :options="
                  pgList.filter((item) => {
                    return (
                      item.currency == value.contract_currency
                    );
                  })"
                  :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select PG" label="" track-by="payment_gateway_name" class="pb-1" :preselect-first="false" :disabled="loading" @input="makeEnabledIfGotData(value)">
                  <template slot="option" slot-scope="props"><span class="option__title cursor-pointer">{{ props.option.payment_gateway_name}}</span></template>
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="custom__tag"><span class="badge font-size-14 p-1 px-2 me-1 mb-md-0 mb-1 bg-soft-warning text-orange cursor-pointer">{{ option.payment_gateway_name }} <span class="custom__remove" @click="remove(option.payment_gateway_name),makeEnabledIfGotData(value)"><i class="uil uil-check-circle"></i></span></span>
                  </span>
                    </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                  </multiselect>

                </div>

              </div>
           </div>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-merchant-edit-pg'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="updateMerchantPGRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Updating...</span>
                 <span v-else> Update </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-merchant-contract" size="xl" centered hide-footer content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-usd-circle me-1 text-primary"></i> Merchant's Contract
           </h5>
        </template>
        <div class="row align-items-top">
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Merchant Username</label>
                 <div>
                  <span class="badge bg-soft-info font-size-14">{{ updateData.account_username }}</span>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Merchant Name</label>
                 <div>
                  <span class="badge bg-soft-info font-size-14" v-if="updateData.account_db_code!=='-1'">{{ updateData.account_name }}</span>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Merchant Code</label>
                 <div><span class="badge bg-soft-info font-size-14">{{ formEditData.merchant_code || '-'}}</span></div>
              </div>
              <div class="mb-4 col-12 col-lg-6">
                 <label class="mb-1">Merchant Key</label>
                 <div><span class="badge bg-soft-info font-size-14">{{ formEditData.merchant_key || '-'}}</span></div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Website</label>
                 <div>
                  <span class="text-muted font-size-14">
                    <a :href="'https://'+formEditData.websiteUrl" target="_blank">{{ formEditData.websiteUrl || '-'}} </a>
                  </span>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Remarks</label>
                 <div>
                  <span class="text-muted font-size-14">{{ formEditData.remarks || '-'}} </span>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Payin Callback Url</label>
                 <div>
                  <span class="text-muted font-size-14">
                    <a :href="'https://'+formEditData.callBackUrl" target="_blank">{{ formEditData.callBackUrl || '-'}} </a>
                  </span>
                </div>
              </div>
              <div class="mb-4 col-12 col-lg-6">
                 <label class="mb-1">Category</label>
                 <div><span class="badge bg-soft-info font-size-14 text-capitalize me-1" v-for="(value, index) in formEditData.category" :key="index">{{ value || '-'}}</span></div>
              </div>
              <div class="mb-4 col-12 col-lg-6">
                 <label class="mb-1">Whitelist IP Addresses</label>
                 <div>
                  <span class="badge bg-light text-muted px-2 font-size-14 text-capitalize me-1 mb-1" v-for="(value, index) in formEditData.whitelistIP.split(',')" :key="index"><code class="text-secondary">{{ value || '-'}}</code></span>

                  <!-- <span class="text-muted font-size-14">{{ formEditData.whitelistIP || '-'}} </span> -->
                </div>
              </div>
              
              <div class="mb-4 col-12 col-lg-6">
                 <label class="mb-1">Code for Support</label>
                 <div><span class="badge bg-soft-info font-size-14">{{ formEditData.code_for_support_skype || '-'}}</span></div>
              </div>

              <div class="mb-4 col-12 col-lg-12">
                 <label class="mb-1">Time Zone</label>
                 <div class="text-muted">{{formEditData.timeZone.name}} UTC{{formEditData.timeZone.value}}</div>
              </div>
              <hr>
              <div v-if="updateData.username!==defaultName" class="mb-3">
                <label class="mb-1 text-capitalize">Merchant under below reseller</label>
                <div class="form-control bg-light border-0" v-if="uplineInfo.length"> {{ uplineInfo[uplineInfo.length - 1].name }}</div>

              </div>
              <div class="mb-3">
                <label>Currency Enabled </label>
                <div v-if="formEditData.contract_selected.length">
                  <span class="badge bg-soft-success font-size-14 py-1 px-2 me-1 mb-2" v-for="(value, index) in formEditData.contract_selected" :key="index">
                    <span v-if="value.status==1">{{value.name}}</span>
                  </span>
                   <div v-if="!formEditData.contract_selected.some(e => e.status==1)"> -</div>
                </div>
                <div v-else>-</div>
              </div>
              <div class="table-responsive text-nowrap font-size-14">
              <table class="table table-bordered align-middle placeholder-glow" v-if="loading">
                <thead class="custom_top_data_bar text-uppercase">
                  <tr>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="d-none">
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr class="d-none"><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                  <tr class="d-none">
                    <td>
                    </td>
                    <td class="align-top text-wrap">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr class="d-none"><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                  </tr>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mb-3" v-for="(value, index) in contracts" :key="index">
                <div v-if="value.status==1">
                <div class="d-flex text-lg-end mb-1">
                    <h4 class="font-size-14">Currency Rate and Extra Charges ({{ value.currency || '-' }})</h4>
                </div>

                <div class="table-responsive text-nowrap font-size-14 mb-3">
                  <table class="table table-bordered align-middle mb-0">
                    <thead class="custom_top_data_bar text-uppercase font-size-12">
                      <tr>
                        <th>Contract <br>Currency</th>
                        <th>Payin Rate <br>and Extra Charges</th>
                        <th>Payout Rate <br>and Extra Charges</th>
                        <th>Settlement Rate<br>and Extra Charges</th>
                        <th>E-Wallet Rate <br> and Extra Charges</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Company/Upline</td>
                        <td>
                          <div class="row text-center">
                            <div class="col">
                            {{uplineCost[index].payin_rate.toFixed(2)}}%
                            </div>
                            <div class="col">
                             + {{uplineCost[index].payin_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="row text-center">
                            <div class="col">
                            {{uplineCost[index].payout_rate.toFixed(2)}}%
                            </div>
                            <div class="col">
                             + {{uplineCost[index].payout_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="row text-center">
                            <div class="col">
                            {{uplineCost[index].settlement_rate.toFixed(2)}}%
                            </div>
                            <div class="col">
                             + {{uplineCost[index].settlement_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="row text-center">
                            <div class="col">
                            {{uplineCost[index].ewallet_rate.toFixed(2)}}%
                            </div>
                            <div class="col">
                             + {{uplineCost[index].ewallet_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Reseller Earning Rate and Extra Charges  ({{ value.currency || '-' }})</td></tr>                      
                      <tr class="bg-soft-success">
                        <td>Merchant 
                          <input type="text" class="form-control" v-model="value.currency" hidden>
                        </td>
                        <td class="text-wrap text-center">
                          <div class="row">
                            <div class="col-6">
                             {{value.payin_rate.toFixed(2)}}%
                            </div>
                            <div class="col-6">
                              {{value.payin_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                        <td class="text-wrap text-center">
                          <div class="row">
                            <div class="col-6">
                             {{value.payout_rate.toFixed(2)}}%
                            </div>
                            <div class="col-6">
                             {{value.payout_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                        <td class="text-wrap text-center">
                          <div class="row">
                            <div class="col-6">
                              {{value.settlement_rate.toFixed(2)}}%
                            </div>
                            <div class="col-6">
                              {{value.settlement_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                        <td class="text-wrap text-center">
                          <div class="row">
                            <div class="col-6">
                              {{value.ewallet_rate.toFixed(2)}}%
                            </div>
                            <div class="col-6">
                              {{value.ewallet_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">{{ updateData.account_name }} - Rate and Extra Charges  ({{ value.currency || '-' }})</td>
                      </tr>
                      <tr>
                        <td>{{ value.currency || '-' }} </td>
                        <td>
                          <div class="row text-center">
                            <div class="col">
                              {{ ownCost[index].payin_rate.toFixed(2) }}%
                            </div>
                            <div class="col">
                             + {{ ownCost[index].payin_extra_charge.toFixed(2) }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="row text-center">
                            <div class="col">
                              {{ ownCost[index].payout_rate.toFixed(2) }}%
                            </div>
                            <div class="col">
                             + {{ ownCost[index].payout_extra_charge.toFixed(2) }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="row text-center">
                            <div class="col">
                              {{ ownCost[index].settlement_rate.toFixed(2) }}%
                            </div>
                            <div class="col">
                             + {{ ownCost[index].settlement_extra_charge.toFixed(2) }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="row text-center">
                            <div class="col">
                              {{ ownCost[index].ewallet_rate.toFixed(2) }}%
                            </div>
                            <div class="col">
                             + {{ ownCost[index].ewallet_extra_charge.toFixed(2) }}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
              <div class="col-12">
                  <hr>
                  <div class="d-grid gap-2 d-md-block text-end">
                    <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-merchant-contract'].hide()">Cancel</button>
                  </div>
              </div>
            </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-merchant-wallet" size="xl" centered hide-footer content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-wallet me-1 text-primary"></i> Merchant's Wallet
           </h5>
        </template>
        <div class="row align-items-center">
              <div class="mb-3">
                 <label class="mb-1">Merchant's Name</label>
                 <div>
                  <span class="badge bg-soft-info font-size-14" v-if="updateData.account_db_code!=='-1'">{{ updateData.account_name }}</span>
                  <span class="badge bg-soft-info font-size-14" v-else>David</span>
                </div>
              </div>
              <hr>
              <div v-if="updateData.username!==defaultName" class="mb-3">
                <label class="mb-1 text-capitalize">Merchant under below reseller</label>
                <div class="form-control bg-light border-0" v-if="uplineInfo.length"> {{ uplineInfo[uplineInfo.length - 1].name }}</div>
              </div>
              <div class="table-responsive text-nowrap font-size-14">
              <table class="table align-middle placeholder-glow" v-if="loading">
                <thead class="custom_top_data_bar text-uppercase">
                  <tr>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="d-none">
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr class="d-none"><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                  <tr class="d-none">
                    <td>
                    </td>
                    <td class="align-top text-wrap">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr class="d-none"><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                  </tr>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mb-1" v-if="!loading">
                <div>
                <div class="d-flex text-lg-end">
                    <h4 class="font-size-15">Wallet</h4>
                </div>

                <div class="table-responsive table-hover text-nowrap font-size-14 mb-2">
                  <table class="table align-middle">
                    <thead class="custom_top_data_bar text-uppercase font-size-12">
                      <tr>
                        <th>Currency</th>
                        <th class="text-end">Payin</th>
                        <th class="text-end">E-Wallet</th>
                        <th class="text-end">Payout</th>
                        <th class="text-end">Settlement</th>
                        <th class="text-end">Contract Balance</th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr v-for="(value, index) in wallets" :key="index">
                        <td>{{value.currency}}</td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.total_payin, true)}}
                        </td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.total_ewallet, true)}}
                        </td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.total_payout, true)}}
                        </td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.total_settlement, true)}}
                        </td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.contract_balance, true)}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
              <div class="col-12">
                  <hr>
                  <div class="d-grid gap-2 d-md-block text-end">
                    <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-merchant-wallet'].hide()">Cancel</button>
                  </div>
              </div>
            </div>
     </b-modal>
     <b-modal @hidden="resetModal2" ref="modal-reset-password" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Reset Password
           </h5>
        </template>
        <div class="row align-items-top">
          <div class="mb-3 col-12 col-md-12">
              <label>Username </label>
              <div><span class="badge bg-soft-info font-size-14">{{ updateData.account_username || '-' }}</span></div>
          
          </div>
          <div class="mb-3 col-12 col-md-12">
                 <label>Password</label>
                 <div class="position-relative">
                    <input
                       v-model="resetPassword.password"
                       :type="passwordFieldType"
                       name="password"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.resetPassword.password.$error,
                       }"
                       placeholder="Password"
                       />
                    <div
                       v-if="modalSubmit && $v.resetPassword.password.$error"
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.resetPassword.password.required"
                          >Password is required.</span
                          >
                       <span v-if="!$v.resetPassword.password.minLength"
                          >Password must be at least 8 characters.</span
                          >
                       <span v-else-if="!$v.resetPassword.password.alpha">
                       Password must be at least 8 characters and contain at least one number, as well as both lowercase and uppercase letters, along with special characters (e.g., HengHeng357#@, Admin12#@, etc.).</span
                          >
                    </div>
                    <div
                       id="eye"
                       class="user-select-none"
                       @click="switchVisibility()"
                       >
                       <i
                          :class="classicon"
                          id="eyetype"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              <div class="mb-3 col-12 col-md-12">
                 <label>Confirm Password</label>
                 <div class="position-relative">
                    <input
                       v-model="resetPassword.confirmPassword"
                       :type="passwordFieldType2"
                       name="confirmPassword"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.resetPassword.confirmPassword.$error,
                       }"
                       placeholder="Confirm Password"
                       />
                    <div
                       v-if="
                       modalSubmit && $v.resetPassword.confirmPassword.$error
                       "
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.resetPassword.confirmPassword.required"
                          >Confirm password is required.</span
                          >
                       <span
                          v-else-if="
                          !$v.resetPassword.confirmPassword.sameAsPassword
                          "
                          >Confirm password does not match the new
                       password.</span
                          >
                    </div>
                    <div
                       id="eye2"
                       class="user-select-none"
                       @click="switchVisibility2()"
                       >
                       <i
                          :class="classicon2"
                          id="eyetype2"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              
          <p class="text-muted font-size-12"><i class="uil uil-exclamation-triangle font-size-15 text-warning me-2"></i> Password is Case Sensitive. </p>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                 <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-reset-password'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="resetPasswordRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Reseting...</span>
                 <span v-else> Reset Password </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <Common ref="commonFunc" />
  </div>
</template>
<script>
  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import Multiselect from "vue-multiselect";
  import { 
    required, 
    minLength, 
    sameAs, 
    helpers
    //url
  } from "vuelidate/lib/validators";
  const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
  const validUsername = (value) => {
    const regex = /^[a-z0-9]+$/;
    return regex.test(value);
  };
  // Custom between validator
  const customBetween = (minProp, max) => {
    return helpers.withParams(
      { type: 'between', min: minProp, max: max },
      function (value, parentVm) {
        console.log(parentVm[minProp])
        const minValue = parseFloat(parentVm[minProp]);
        return value >= minValue && value <= max;
      }
    );
  };
  const CancelToken = axios.CancelToken;
  let cancel;
  /**
   * Starter page
   */
  const ResellerDefault =
  {
    "account_db_code": "-1",
    "account_name": "Current Account",
    "account_type": "",
    "up_reseller_count": 0,
    "reseller_parent": [],
    "reseller_level": "",
    "reseller_code": "",
    "merchant_count": 0
  }
  export default {
    components: {
      Common,
      Multiselect
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        defaultName: appConfig.defaultAccountName,
        updateData: {},
        accessToken: '',
        accessEmail: '',
        accessPhone: '',
        accessUsername: '',
        account_type: '',
        loading: false,
        modalSubmit: false,
        modalLoading: false,
        passwordFieldType_current: "password",
        classicon_current: "uil uil-eye-slash",
        passwordFieldType: "password",
        classicon: "uil uil-eye-slash",
        passwordFieldType2: "password",
        classicon2: "uil uil-eye-slash",
        pgCategory:['gaming','forex','crypto','porn','scam'],
        returnData: [],
        tabs_index:0,
        selectedReseller: ResellerDefault,
        reseller:[ResellerDefault],
        timeZone:[],
        pgList:[],
        ownCost:[],
        contracts:[],
        uplineCost:[],
        wallets:[],
        uplineInfo:[],
        quickUpdate:{
          all_value:"",
          payin_rate: "",
          payin_extra_charge: "",
          payout_rate: "",
          payout_extra_charge: "",
          settlement_rate: "",
          settlement_extra_charge: "",
          ewallet_rate: "",
          ewallet_extra_charge: "",
        },
        formData: {
          username:"",
          password: "",
          confirmPassword: "",
          name:"",
          websiteUrl:"",
          whitelistIP:"",
          callBackUrl:"",
          remarks:"",
          category:[],
          timeZone:{
            "name": "Default",
            "time_zone": "UTC",
            "value": "+0:00"
          },
          contract_selected:[],
        },
        formEditData: {
          id:"",
          merchant_code:"",
          code_for_support_skype:"",
          merchant_key:"",
          name:"",
          status:"",
          status_flag:"",
          login_block:"",
          api_enabled:"",
          websiteUrl:"",
          whitelistIP:"",
          callBackUrl:"",
          remarks:"",
          category:[],
          timeZone:"",
          contract_selected:[],
        },
        formEditPGData: {
          id:"",
          merchant_code:"",
          merchant_key:"",
          name:"",
        },
        resetPassword: {
          id:"",
          password: "",
          confirmPassword: ""
        },
        copyMessages:""
      };
    },
    validations: {
      formData: {
        username: {
          required,
          validUsername
        },
        name: {
          required,
        },
        category: {
          required,
        },
        timeZone: {
          required,
        },
        websiteUrl: {
          required,
        },
        password: {
          required,
          alpha,
          minLength: minLength(8),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs("password"),
        },
        contract_selected: {
          required,
          $each: {
            currency:{
              required,
            },
            payin_rate: {
              required,
              between: customBetween('min_payin_rate', 50)
            },
            payin_extra_charge: {
              required,
              between: customBetween('min_payin_extra_charge', 100000)
            },
            payout_rate: {
              required,
              between: customBetween('min_payout_rate', 50)
            },
            payout_extra_charge: {
              required,
              between: customBetween('min_payout_extra_charge', 100000)
            },
            settlement_rate: {
              required,
              between: customBetween('min_settlement_rate', 50)
            },
            settlement_extra_charge: {
              required,
              between: customBetween('min_settlement_extra_charge', 100000)
            },
            ewallet_rate: {
              required,
              between: customBetween('min_ewallet_rate', 50)
            },
            ewallet_extra_charge: {
              required,
              between: customBetween('min_ewallet_extra_charge', 100000)
            }
          }
        },
       
      },
      formEditData: {
        name: {
          required,
        },
        websiteUrl: {
          required,
        },
        category: {
          required,
        },
        timeZone: {
          required,
        },
        contract_selected: {
          required,
          $each: {
            currency:{
              required,
            },
            payin_rate: {
              required,
              between: customBetween('min_payin_rate', 50)
            },
            payin_extra_charge: {
              required,
              between: customBetween('min_payin_extra_charge', 100000)
            },
            payout_rate: {
              required,
              between: customBetween('min_payout_rate', 50)
            },
            payout_extra_charge: {
              required,
              between: customBetween('min_payout_extra_charge', 100000)
            },
            settlement_rate: {
              required,
              between: customBetween('min_settlement_rate', 50)
            },
            settlement_extra_charge: {
              required,
              between: customBetween('min_settlement_extra_charge', 100000)
            },
            ewallet_rate: {
              required,
              between: customBetween('min_ewallet_rate', 50)
            },
            ewallet_extra_charge: {
              required,
              between: customBetween('min_ewallet_extra_charge', 100000)
            }
          }
        }
      },
      formEditPGData: {
        name: {
          required,
        }
      },
      resetPassword: {
        password: {
          required,
          alpha,
          minLength: minLength(8),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs("password"),
        }
      },

    },
    middleware: "authentication",
    async mounted() {
      this.$root.$on('bv::dropdown::hide', bvEvent => {
        console.log('Dropdown is about to be hide', bvEvent)
        //after dismiss
        this.quickUpdate={
            all_value:"",
            payin_rate: "",
            payin_extra_charge: "",
            payout_rate: "",
            payout_extra_charge: "",
            settlement_rate: "",
            settlement_extra_charge: "",
            ewallet_rate: "",
            ewallet_extra_charge: "",
        }
      })
      this.reload();
      //   this.title = PageTitle
      //   this.items[1].text = PageTitle
      //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
      //   // this.$refs.main.changeVerticalTopBar("bill",true)
      //   // this.$refs.main.setShowFooterCert(false)
      //   // this.$refs.main.setPageTitle('title')
      //   console.log( this.$refs.commonFunc.getPhone())
      //   this.accessToken = this.$refs.commonFunc.getToken()
      //   this.accessUsername = this.$refs.commonFunc.getUsername()
    },
    created() {
    },
    methods: {
    getTimeZone(){
        console.log("getTimeZone")
        this.$Progress.start();
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        const uniqueParam = `nocache=${new Date().getTime()}`;
        axios({
          method: "get",
          url: '/api/time_zone.json?' + uniqueParam,
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.timeZone = []
            resData.time_zone.forEach((element) => {
              this.timeZone.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          }
          this.disabled = false
          this.$Progress.finish();
        }).catch((error) => {
          this.disabled = false
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        });
    },
    getReseller(type) {
      if(cancel !== undefined) cancel();
      console.log("getReseller")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "999999");
      bodyFormData.append("resellerId", (this.account_type==='admin') ? "" : "all", );
      bodyFormData.append("searchReseller", "")
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllResellerList',
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        cancelToken: new CancelToken(c => cancel = c)
      }).then((response) => {
        var resData = response.data;
        if (resData.status == 200) {
          this.reseller = []
          this.selectedReseller = ResellerDefault
          resData.data.forEach((element) => {
            if (element.account_username!==this.defaultName){
              this.reseller.push(element);
            }
          });
          this.selectedReseller = this.reseller[0]
          // if (type=='create'){
          //   resData.data.forEach((element) => {
          //   this.reseller.push(element);
          //   });
          //   this.selectedReseller = this.reseller[0]
          // }else if (type=='edit'){
          //   const parentId = this.data.updateData.reseller_parent[this.data.updateData.reseller_parent.length - 1].account_db_code;
          //   console.log(this.data.updateData.reseller_parent)
          //   resData.data.forEach((element) => {
          //     if (element.account_db_code == parentId){
          //       this.reseller.push(element);
          //     }
          //   });
          //   this.selectedReseller = this.reseller[0]
          // }
         

          //if login as reseller
          if (this.account_type=='reseller'){
            this.reseller=[resData.current_account]
            this.selectedReseller = this.reseller[0]
          }
          
          if(type=='create'){
            this.getData()
          }else if(type=='edit'){
            this.getEditData()
          }else if(type=='editWithPg'){
            this.getEditDataWithPG()
          }
          

        } else if (resData.status == 440) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          localStorage.clear();
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          this.loading = false;
          this.disabled = false
        }
        this.$Progress.finish();
      }).catch((error) => {
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    //     this.$Progress.start();
    //     this.loading = true
    //     var bodyFormData = new FormData();
    //     bodyFormData.append("accessUsername",  this.accessUsername);
    //     bodyFormData.append("accessToken", this.accessToken);
    //     bodyFormData.append("reseller", this.selectedReseller.account_db_code);
        
    //     axios({
    //         method: "post",
    //         url: appConfig.DemoAPI,
    //         data: bodyFormData,
    //         headers: { "Content-Type": "multipart/form-data" },
    //     })
    //     .then((response)=>{
    //         var resData = response.data;
    //         if (resData.status == 200) {
    //           console.log(resData)
    //           //this.reload()
    //          const dataReturn =[
    //           {
    //             contract_name: "Malaysia (MYR)",
    //             contract_currency: "MYR",
    //             payin_rate: "1.30",
    //             payin_extra_charge: "0",
    //             payout_rate: "1.30",
    //             payout_extra_charge: "0",
    //             settlement_rate: "1.30",
    //             settlement_extra_charge: "0",
    //             ewallet_rate: "0",
    //             ewallet_extra_charge: "0",
    //           },
    //           {
    //             contract_name: "India (INR)",
    //             contract_currency: "INR",
    //             payin_rate: "0.90",
    //             payin_extra_charge: "0",
    //             payout_rate: "0.90",
    //             payout_extra_charge: "0",
    //             settlement_rate: "0.90",
    //             settlement_extra_charge: "0",
    //             ewallet_rate: "0",
    //             ewallet_extra_charge: "0",
    //           },
    //           ]
    //           this.uplineCost= dataReturn
    //           dataReturn.forEach(element => {
    //             this.formData.contract_selected.push(
    //               {
    //                 name: element.contract_name,
    //                 currency: element.contract_currency,
    //                 payin_rate: "0",
    //                 payin_extra_charge: "0",
    //                 payout_rate: "0",
    //                 payout_extra_charge: "0",
    //                 settlement_rate: "0",
    //                 settlement_extra_charge: "0",
    //                 ewallet_rate: "0",
    //                 ewallet_extra_charge: "0",
    //                 status:0
    //               }
    //             );
    //           });
    //         } 
    //         else if (resData.status == 440){
    //           Swal.fire(
    //               {
    //               icon: 'error',
    //               title: 'Oops...',
    //               html: `${resData.message}.`,
    //               confirmButtonColor: '#222',
    //               confirmButtonText: this.$t('siteLang.Done'),
    //             })
    //             localStorage.clear();
    //             this.$router.push({
    //                 path: "/login",
    //           });
    //         }
    //         else {
    //             Swal.fire(
    //               {
    //               icon: 'error',
    //               title: 'Oops...',
    //               html: `${resData.message}.`,
    //               confirmButtonColor: '#222',
    //               confirmButtonText: this.$t('siteLang.Done'),
    //             })
    //         }
    //         this.loading = false;
    //         this.$Progress.finish();
    //     })
    //     .catch((error)=> {
    //         this.loading = false;
    //         this.$Progress.finish();
    //         //Swal.fire("Error", error, "error");
    //         Swal.fire(
    //           {
    //           icon: 'error',
    //           title: 'Oops...',
    //           html: `${error}.`,
    //           confirmButtonColor: '#222',
    //           confirmButtonText: this.$t('siteLang.Done'),
    //         })
    //   });
    // },
    getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("resellerId", this.selectedReseller.account_db_code);
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/getResellerDetail',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
            
             //clear data
             this.wallets=[];
             this.ownCost=[];
             this.contracts=[];
             this.uplineCost=[];
             this.formData.contract_selected=[]
             //
             var returnData = resData.data;
             var upline_contracts= []
             returnData.own_cost_list.forEach(element => {
              //added && element.contract_id!=='NEW' on 15 Mar
              if (element.initial_contact_enable && element.contract_id!=='NEW'){
              upline_contracts.push(
                {
                  contract_name: element.contract_name,
                  contract_currency: element.contract_currency,
                  payin_rate: element.payin_rate,
                  payin_extra_charge: element.payin_extra_charges,
                  payout_rate: element.payout_rate,
                  payout_extra_charge: element.payout_extra_charges,
                  settlement_rate: element.settlement_rate,
                  settlement_extra_charge: element.settlement_extra_charges,
                  ewallet_rate: element.ewallet_rate,
                  ewallet_extra_charge: element.ewallet_extra_charges,
                  status: (element.contract_status  === 'available') ? 1 : 0,
                  upline_disable: element.upline_disable
                },
              )
              //follow parent upline contract and push fake
              this.formData.contract_selected.push(
                {
                  name: element.contract_name,
                  currency: element.contract_currency,
                  payin_rate: "0",
                  payin_extra_charge: "0",
                  payout_rate: "0",
                  payout_extra_charge: "0",
                  settlement_rate: "0",
                  settlement_extra_charge: "0",
                  ewallet_rate: "0",
                  ewallet_extra_charge: "0",
                  min_payin_rate: `-${element.payin_rate}`,
                  min_payin_extra_charge: `-${element.payin_extra_charges}`,
                  min_payout_rate: `-${element.payout_rate}`,
                  min_payout_extra_charge: `-${element.payout_extra_charges}`,
                  min_settlement_rate: `-${element.settlement_rate}`,
                  min_settlement_extra_charge: `-${element.settlement_extra_charges}`,
                  min_ewallet_rate: `-${element.ewallet_rate}`,
                  min_ewallet_extra_charge: `-${element.ewallet_extra_charges}`,
                  status:0,
                }
              )
              }
             });
             this.uplineCost = upline_contracts;
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    getEditData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("resellerId", this.selectedReseller.account_db_code);
        bodyFormData.append("merchantId", this.data.updateData.account_db_code);
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/getMerchantDetail',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
             console.log(resData)
              //this.reload()
             var returnData = resData.data;
             var upline_contracts= []
             var contracts = []
             var wallets = []
             var downlineTotalCost = []
             returnData.reseller_contract.forEach(element => {
              upline_contracts.push(
                {
                  contract_name: element.contract_name,
                  contract_currency: element.contract_currency,
                  payin_rate: element.payin_rate,
                  payin_extra_charge: element.payin_extra_charges,
                  payout_rate: element.payout_rate,
                  payout_extra_charge: element.payout_extra_charges,
                  settlement_rate: element.settlement_rate,
                  settlement_extra_charge: element.settlement_extra_charges,
                  ewallet_rate: element.ewallet_rate,
                  ewallet_extra_charge:element.ewallet_extra_charges,
                  upline_disable:element.upline_disable,
                  status: (element.contract_status  === 'available') ? 1 : 0,
                }
              )
             });
             returnData.contract.forEach(element => {
              contracts.push(
                {
                    contract_name: element.contract_name,
                    contract_currency: element.contract_currency,
                    payin_rate: element.payin_rate,
                    payin_extra_charge: element.payin_extra_charges,
                    payout_rate: element.payout_rate,
                    payout_extra_charge: element.payout_extra_charges,
                    settlement_rate: element.settlement_rate,
                    settlement_extra_charge: element.settlement_extra_charges,
                    ewallet_rate:  element.ewallet_rate,
                    ewallet_extra_charge: element.ewallet_extra_charges,
                    status: (element.contract_status  === 'available') ? 1 : 0,

                    total_payin: element.total_payin,
                    total_payout: element.total_payout,
                    total_settlement: element.total_settlement,
                    contract_total: element.contract_total,
                    contract_balance: element.contract_balance,
                 }
              )
             });

             returnData.own_cost_list.forEach(element => {
              downlineTotalCost.push(
                {
                    contract_name: element.contract_name,
                    contract_currency: element.contract_currency,
                    payin_rate: element.payin_rate,
                    payin_extra_charge: element.payin_extra_charges,
                    payout_rate: element.payout_rate,
                    payout_extra_charge: element.payout_extra_charges,
                    settlement_rate: element.settlement_rate,
                    settlement_extra_charge: element.settlement_extra_charges,
                    ewallet_rate:  element.ewallet_rate,
                    ewallet_extra_charge: element.ewallet_extra_charges,
                    status: (element.contract_status  === 'available') ? 1 : 0,

                    total_payin: element.total_payin,
                    total_payout: element.total_payout,
                    total_settlement: element.total_settlement,
                    contract_total: element.contract_total,
                    contract_balance: element.contract_balance,
                 }
              )
             });
             
             
             returnData.wallets.forEach(element => {
              wallets.push(
                {
                  currency: element.contract_currency,
                  total_payin: element.total_payin,
                  total_ewallet: element.total_ewallet,
                  total_payout: element.total_payout,
                  total_settlement: element.total_settlement,
                  contract_total:element.contract_total,
                  contract_balance: element.contract_balance,
                },
              )
             });
             
             
             var dataReturn ={
                name: returnData.account_name,
                username: returnData.account_username,
                website_url:returnData.website_url,
                whitelist_ip:returnData.whitelist_ip,
                callback_url:returnData.callback_url,
                remarks: returnData.remarks || '',
                category: returnData.category || [],
                time_zone: returnData.time_zone || {
                    "name": "Default",
                    "time_zone": "UTC",
                    "value": "+0:00"
                },
                status:returnData.status,
                status_flag: returnData.status_flag,
                login_block: returnData.login_block,
                api_enabled: returnData.api_enabled || 0,
                merchant_code: returnData.merchant_code,
                merchant_key: returnData.merchant_key,
                code_for_support_skype: returnData.code_for_support_skype || returnData.merchant_code,
                upline_contract: upline_contracts,
                contract:contracts,
                wallets: wallets,
                reseller_parent: returnData.reseller_parent,
                downlineTotalCost: downlineTotalCost
              }
             
              this.formEditData.name = dataReturn.name
              this.formEditData.websiteUrl = dataReturn.website_url
              this.formEditData.whitelistIP = dataReturn.whitelist_ip
              this.formEditData.callBackUrl = dataReturn.callback_url
              this.formEditData.remarks = dataReturn.remarks
              this.formEditData.category = dataReturn.category.split(',')
              this.formEditData.timeZone = dataReturn.time_zone
              this.formEditData.status = (dataReturn.status === 'active') ? 1 : 0
              this.formEditData.status_flag = dataReturn.status_flag
              this.formEditData.login_block = dataReturn.login_block
              this.formEditData.api_enabled = dataReturn.api_enabled
              this.updateData.username= dataReturn.username
              this.formEditData.merchant_code = dataReturn.merchant_code
              this.formEditData.merchant_key = dataReturn.merchant_key
              this.formEditData.code_for_support_skype = dataReturn.code_for_support_skype
              this.uplineCost= dataReturn.upline_contract
              this.uplineInfo= dataReturn.reseller_parent
              dataReturn.contract.forEach((element,index) => {
                this.formEditData.contract_selected.push(
                  {
                    name: element.contract_name,
                    currency: element.contract_currency,
                    payin_rate: element.payin_rate,
                    payin_extra_charge: element.payin_extra_charge,
                    payout_rate: element.payout_rate,
                    payout_extra_charge: element.payout_extra_charge,
                    settlement_rate: element.settlement_rate,
                    settlement_extra_charge: element.settlement_extra_charge,
                    ewallet_rate: element.ewallet_rate,
                    ewallet_extra_charge: element.ewallet_extra_charge,
                    status: element.status,
                    total_payin: element.total_payin,
                    total_payout: element.total_payout,
                    total_settlement: element.total_settlement,
                    contract_total:element.contract_total,
                    contract_balance: element.contract_balance,
                    //2024-07-02
                    //Copy upline cost applied to min
                    min_payin_rate: `-${this.uplineCost[index].payin_rate}`,
                    min_payin_extra_charge: `-${this.uplineCost[index].payin_extra_charge}`,
                    min_payout_rate: `-${this.uplineCost[index].payout_rate}`,
                    min_payout_extra_charge: `-${this.uplineCost[index].payout_extra_charge}`,
                    min_settlement_rate: `-${this.uplineCost[index].settlement_rate}`,
                    min_settlement_extra_charge: `-${this.uplineCost[index].settlement_extra_charge}`,
                    min_ewallet_rate: `-${this.uplineCost[index].ewallet_rate}`,
                    min_ewallet_extra_charge: `-${this.uplineCost[index].ewallet_extra_charge}`,
                  }
                );
                this.contracts.push(
                  {
                    name: element.contract_name,
                    currency: element.contract_currency,
                    payin_rate: element.payin_rate,
                    payin_extra_charge: element.payin_extra_charge,
                    payout_rate: element.payout_rate,
                    payout_extra_charge: element.payout_extra_charge,
                    settlement_rate: element.settlement_rate,
                    settlement_extra_charge: element.settlement_extra_charge,
                    ewallet_rate: element.ewallet_rate,
                    ewallet_extra_charge: element.ewallet_extra_charge,
                    status: element.status,
                    total_payin: element.total_payin,
                    total_payout: element.total_payout,
                    total_settlement: element.total_settlement,
                    contract_total:element.contract_total,
                    contract_balance: element.contract_balance
                  }
                )
              });
              this.wallets = dataReturn.wallets
              this.ownCost = downlineTotalCost
            
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    makeEnabledIfGotData(value){
      if (value.selected_pg.length>0){
        value.manual_pg = 1
      }else{
        value.manual_pg = 0
      }
    },
    getEditDataWithPG(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("resellerId", this.selectedReseller.account_db_code);
        bodyFormData.append("merchantId", this.data.updateData.account_db_code);
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/getMerchantDetailWithPg',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
             console.log(resData)
              //this.reload()
             var returnData = resData.data;
             var currentContract = []
             
             returnData.own_cost_list.forEach(element => {
              currentContract.push(
                {
                    contract_name: element.contract_name,
                    contract_currency: element.contract_currency,
                    status: element.status,
                    manual_pg: element.manual_pg,
                    selected_pg: element.selected_pg
                }
              )
            })
             
             var dataReturn ={
                name: returnData.account_name,
                username: returnData.account_username,
                merchant_code: returnData.merchant_code,
                merchant_key: returnData.merchant_key,
                contract: currentContract,
              }
              
              this.formEditPGData.name = dataReturn.name
              this.updateData.username= dataReturn.username
              this.formEditPGData.merchant_code = dataReturn.merchant_code
              this.formEditPGData.merchant_key = dataReturn.merchant_key
              this.ownCost = dataReturn.contract
            
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    getPgList(){
      console.log("getPgList")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername",  this.accessUsername);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getPgListShort',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          var resData = response.data;
          if (resData.status == 200) {
            this.pgList = []
            resData.pg_list.forEach(element => {
              this.pgList.push({
                  payment_gateway:`${element.payment_gateway_name} (${element.currency})`,
                  payment_gateway_name: element.payment_gateway_name,
                  payment_gateway_code: element.payment_gateway_code,
                  currency: element.currency
                });
            })
          }
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    switchVisibility() {
      (this.classicon =
        this.classicon === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password");
    },
    switchVisibility2() {
      (this.classicon2 =
        this.classicon2 === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType2 =
          this.passwordFieldType2 === "password" ? "text" : "password");
    },
    updateAllInput(){
      if (this.quickUpdate.all_value!==''){
        this.quickUpdate={
            all_value:this.quickUpdate.all_value,
            payin_rate: this.quickUpdate.all_value,
            payin_extra_charge: this.quickUpdate.all_value,
            payout_rate: this.quickUpdate.all_value,
            payout_extra_charge: this.quickUpdate.all_value,
            settlement_rate: this.quickUpdate.all_value,
            settlement_extra_charge: this.quickUpdate.all_value,
            ewallet_rate: this.quickUpdate.all_value,
            ewallet_extra_charge: this.quickUpdate.all_value,
        }
      }
    },
    quickUpdateRateAndExtraCharge(form){
      if (this[form].contract_selected.length){
        this[form].contract_selected.forEach(element => {
            if (this.quickUpdate.payin_rate!==''){
              element.payin_rate = this.quickUpdate.payin_rate
            }
            if (this.quickUpdate.payin_extra_charge!==''){
              element.payin_extra_charge = this.quickUpdate.payin_extra_charge
            }
            if (this.quickUpdate.payout_rate!==''){
              element.payout_rate = this.quickUpdate.payout_rate
            }
            if (this.quickUpdate.payout_extra_charge!==''){
              element.payout_extra_charge = this.quickUpdate.payout_extra_charge
            }
            if (this.quickUpdate.settlement_rate!==''){
              element.settlement_rate = this.quickUpdate.settlement_rate
            }
            if (this.quickUpdate.settlement_extra_charge!==''){
              element.settlement_extra_charge = this.quickUpdate.settlement_extra_charge
            }
            if (this.quickUpdate.ewallet_rate!==''){
              element.ewallet_rate = this.quickUpdate.ewallet_rate
            }
            if (this.quickUpdate.ewallet_extra_charge!==''){
              element.ewallet_extra_charge = this.quickUpdate.ewallet_extra_charge
            }
        });
      }
      if (form=='formData'){
        this.$refs.dropdown.hide(true)
      }else if  (form=='formEditData'){
        this.$refs.dropdown2.hide(true)
      }
      
    },
    resetRateAndExtraCharge(form){
      if (this[form].contract_selected.length){
        this[form].contract_selected.forEach(element => {
          element.payin_rate = 0
          element.payin_extra_charge = 0
          element.payout_rate = 0
          element.payout_extra_charge = 0
          element.settlement_rate = 0
          element.settlement_extra_charge = 0
          element.ewallet_rate = 0
          element.ewallet_extra_charge = 0
        });
      }
      if (form=='formData'){
        this.$refs.dropdown.hide(true)
      }else if  (form=='formEditData'){
        this.$refs.dropdown2.hide(true)
      }
    },
    inputNumberOnly(event, parentModel, childModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this[parentModel][childModel] = numericValue
    },
    inputNumberOnlyV2(event, parentModel, childModel) {
      var numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this[parentModel][childModel] = numericValue
      // if(numericValue.length > 2){
      //   var val = numericValue.replace(/[^0-9]/g, '');
      //   console.log(val);
      //   val = val.substr(0, val.length-2)+"."+val.substr(-2);
      //   this[parentModel][childModel] = val;
      // }
    },
    inputNumberOnlyV3(event, parentModel, childModel, index) {
      var numericValue = event.target.value.replace(/[^0-9.]/g, '');
      //this[parentModel][childModel] = numericValue
      this[parentModel].contract_selected[index][childModel] = numericValue
      // if(numericValue.length > 2){
      //   var val = numericValue.replace(/[^0-9]/g, '');
      //   console.log(val);
      //   val = val.substr(0, val.length-2)+"."+val.substr(-2);
      //   this[parentModel].contract_selected[index][childModel] = val;
      // }
    },
    inputNumberOnlyV4(event, parentModel, childModel, index) {
      //can be use -
      var numericValue = event.target.value.replace(/[^0-9.-]/g, '');
      //this[parentModel][childModel] = numericValue
      this[parentModel].contract_selected[index][childModel] = numericValue
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    showModal() {
      this.$refs['modal-merchant'].show()
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData;
      this.getTimeZone();
      this.getReseller('create');
    },
    showEditModal() {
      this.$refs['modal-merchant-edit'].show()
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.formEditData.id = this.data.updateData.account_db_code

      this.resetPassword =  {
        id:this.data.updateData.account_db_code,
        password: "",
        confirmPassword: ""
      }
      this.getTimeZone();
      this.getReseller('edit');
    },

    showEditModalPgSetting() {
      this.$refs['modal-merchant-edit-pg'].show()
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.formEditPGData.id = this.data.updateData.account_db_code

      this.getReseller('editWithPg');
      this.getPgList();
    },
    showResetPasswordModal() {
      this.$refs['modal-reset-password'].show()
    },
    showViewModal(type) {
      if (type =='contract'){
        this.$refs['modal-merchant-contract'].show()
      }else if (type =='wallet'){
        this.$refs['modal-merchant-wallet'].show()
      }
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.getEditData();
    },
    resetModal() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.timeZone=[];
      this.wallets=[];
      this.ownCost=[];
      this.contracts=[];
      this.uplineCost=[];
      this.formData={
        username:"",
        password: "",
        confirmPassword: "",
        name:"",
        websiteUrl:"",
        whitelistIP:"",
        callBackUrl:"",
        remarks:"",
        category:[],
        timeZone:{
          "name": "Default",
          "time_zone": "UTC",
          "value": "+0:00"
        },
        contract_selected:[],
      }
      this.formEditData = {
        id:"",
        merchant_code:"",
        merchant_key:"",
        code_for_support_skype:"",
        name:"",
        status:"",
        status_flag:"",
        login_block:"",
        api_enabled:"",
        websiteUrl:"",
        whitelistIP:"",
        callBackUrl:"",
        remarks:"",
        category:[],
        timeZone:{
            "name": "Default",
            "time_zone": "UTC",
            "value": "+0:00"
        },
        contract_selected:[],
      }
      this.resetPassword =  {
        id:"",
        password: "",
        confirmPassword: ""
      },
      this.formEditPGData= {
        id:"",
        merchant_code:"",
        merchant_key:"",
        name:"",
      }
    },
    resetModal2() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.resetPassword.password= ""
      this.resetPassword.confirmPassword= ""
    },
    resetPasswordRequest(){
      this.modalSubmit = true
      this.$v.resetPassword.$touch();
      console.log((this.$v.resetPassword.$invalid))

      if (this.$v.resetPassword.$invalid) {
        return;
      }
      else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("accountDbCode", this.resetPassword.id);
        bodyFormData.append("password", this.resetPassword.password);
        axios({
            method: "post",
            url:  appConfig.APIHostAdmin + 'controller/admin/resetResellerMerchantPassword',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'Password Reset Success',
                html: 'Your password for this account has been successfully reset!',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-reset-password'].hide()
                this.$refs['modal-merchant-edit'].hide()
                this.$emit('callParentFunction2');
              })
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    addMerchantRequest(){
      this.modalSubmit = true
      this.$v.formData.$touch();
      console.log((this.$v.formData.$invalid))
      var NoContactEnabled = this.formData.contract_selected.map((item) => parseInt(item.status)).includes(1);

      if (this.$v.formData.$invalid) {
        return;
      }
      else if (!NoContactEnabled) {
        Swal.fire(
          {
          icon: 'error',
          title: 'Oops...',
          html: `Please enable at least one contract.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      }
       else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        var contractData = []
        this.formData.contract_selected.forEach(element => {
          contractData.push({
            name: element.name,
            currency: element.currency,
            payin_rate:element.payin_rate,
            payin_extra_charges: element.payin_extra_charge,
            payout_rate: element.payout_rate,
            payout_extra_charges: element.payout_extra_charge,
            settlement_rate:element.settlement_rate,
            settlement_extra_charges: element.settlement_extra_charge,
            ewallet_rate: element.ewallet_rate,
            ewallet_extra_charges: element.ewallet_extra_charge,
            status: element.status
          })
        });
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("name", this.formData.name);
        bodyFormData.append("username", this.formData.username);
        bodyFormData.append("password", this.formData.password);
        bodyFormData.append("resellerId", this.selectedReseller.account_db_code);
        bodyFormData.append("websiteUrl", this.formData.websiteUrl);
        bodyFormData.append("remarks", this.formData.remarks);
        bodyFormData.append("category", this.formData.category.toString());
        bodyFormData.append("timeZone", JSON.stringify(this.formData.timeZone));
        bodyFormData.append("whitelistIP", this.formData.whitelistIP);
        bodyFormData.append("callbackUrl", this.formData.callBackUrl);
        bodyFormData.append("contracts", JSON.stringify(contractData));
        axios({
            method: "post",
            //url:  appConfig.DemoAPI,
            url: appConfig.APIHostAdmin + 'controller/admin/addMerchant',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.copyMessages = `Welcome to ${appConfig.title}!\nYour merchant account is ready!\n\nURL: https://portal.zpay.world\n\nUsername: ${this.formData.username}\nPassword: ${this.formData.password}\n\n** Please go to "Technical" from the Menu to get the Integration API documents and the contacts of our integration team.`
              Swal.fire({
                icon: 'success',
                title: `Welcome to ${appConfig.title}!`,
                //html: 'Your merchant account has been successfully created!',
                html: `
                <p>Your merchant account is ready!</p>
                <p>URL: <a href="https://portal.zpay.world" target="_blank">https://portal.zpay.world</a></p>
                <p>
                  Username: ${this.formData.username}<br>
                  Password: ${this.formData.password}
                </p>
                <p class="font-size-12">** Please go to "Technical"  from the Menu to get the Integration API documents and the contacts of our integration team.</p>`,
                confirmButtonColor: '#FA6541',
                showCancelButton: true,
                cancelButtonText: "Cancel",
                confirmButtonText: `<i class="bi bi-copy"></i> Copy Message`,
              }).then((result) => {
                console.log(result);
                if (result.isConfirmed) {
                  Swal.fire("Message Copied!", "You can paste and send to your merchant.", "success");
                  const msg = this.copyMessages;
                  navigator.clipboard.writeText(msg)
                }
                this.copyMessages=""
                this.$refs['modal-merchant'].hide()
                this.$emit('callParentFunction', this.selectedReseller.account_db_code);
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    updateMerchantRequest(){
      this.modalSubmit = true
      this.$v.formEditData.$touch();
      console.log((this.$v.formEditData.$invalid))
      var NoContactEnabled = this.formEditData.contract_selected.map((item) => parseInt(item.status)).includes(1);

      if (this.$v.formEditData.$invalid) {
        return;
      }
      else if (!NoContactEnabled) {
        Swal.fire(
          {
          icon: 'error',
          title: 'Oops...',
          html: `Please enable at least one contract.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      }else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        var contractData =[]
        this.formEditData.contract_selected.forEach(element => {
          contractData.push({
            name: element.name,
            currency: element.currency,
            payin_rate:element.payin_rate,
            payin_extra_charges: element.payin_extra_charge,
            payout_rate: element.payout_rate,
            payout_extra_charges: element.payout_extra_charge,
            settlement_rate:element.settlement_rate,
            settlement_extra_charges: element.settlement_extra_charge,
            ewallet_rate: element.ewallet_rate,
            ewallet_extra_charges: element.ewallet_extra_charge,
            status: element.status
          })
        });
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("name", this.formEditData.name);
        bodyFormData.append("merchantId", this.formEditData.id);
        bodyFormData.append("websiteUrl", this.formEditData.websiteUrl);
        bodyFormData.append("remarks", this.formEditData.remarks);
        bodyFormData.append("category", this.formEditData.category.toString());
        bodyFormData.append("timeZone", JSON.stringify(this.formEditData.timeZone));
        bodyFormData.append("whitelistIP", this.formEditData.whitelistIP);
        bodyFormData.append("callbackUrl", this.formEditData.callBackUrl);
        bodyFormData.append("status", this.formEditData.status);
        bodyFormData.append("loginBlock", this.formEditData.login_block);
        bodyFormData.append("apiEnabled", this.formEditData.api_enabled);
        bodyFormData.append("contracts", JSON.stringify(contractData));

        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/updateMerchant',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'Merchant Account Updated',
                html: 'Your merchant account has been successfully updated!',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-merchant-edit'].hide()
                this.$emit('callParentFunction2');
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    updateMerchantPGRequest(){
      this.modalSubmit = true
      this.$v.formEditPGData.$touch();
      console.log((this.$v.formEditPGData.$invalid))

      if (this.$v.formEditPGData.$invalid) {
        return;
      }
     else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        var ownCostWithPG = [];
        this.ownCost.forEach(element => {
          var OnOffIfNotSelectPg = 0 
          if (element.selected_pg.length>0){
            OnOffIfNotSelectPg = 1
          }else{
            OnOffIfNotSelectPg = 0
          }
          ownCostWithPG.push(
            {
                contract_name: element.contract_name,
                contract_currency: element.contract_currency,
                status: element.status,
                manual_pg: OnOffIfNotSelectPg,
                selected_pg: element.selected_pg
            }
          )
        })

        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("merchantId", this.formEditPGData.id);
        bodyFormData.append("ownCostWithPG", JSON.stringify(ownCostWithPG));
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/updateMerchantPgList',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'Merchant Payment Gateway Setting Updated',
                html: 'Your merchant payment gateway setting has been successfully updated!',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-merchant-edit-pg'].hide()
                this.$emit('callParentFunction2');
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted
        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
    }
  };
</script>
<style scoped>
  #eye_current,
  #eye,
  #eye2,
  #eye3{
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
  }
  .custom-table td .row{
    min-width:220px;
    
  }
</style>